<template>
  <div class="query-table">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
    >
      <!-- <el-table-column prop="tempId" label="ID" align="center"></el-table-column> -->
      <el-table-column
        type="index"
        label="序号"
        width="50">
      </el-table-column>
      <el-table-column prop="username" label="姓名" align="center" ></el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center" min-width="120">
        <template slot-scope="scope">
          <phone :phone="scope.row.mobile"></phone>
        </template>
      </el-table-column>
      <el-table-column prop="idcard" label="身份证号" align="center" min-width="165">
        <template slot-scope="scope">
          <phone :phone="scope.row.idcard"></phone>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="查询时间" align="center" min-width="155"></el-table-column>
<!--      <el-table-column prop="customer" label="查询客服" align="center"></el-table-column>-->
      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-search"
            circle
            @click="$emit('view', scope.row)"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            @click="$emit('delete', scope.row)"
          ></el-button>
          <el-button
            type="info"
            size="mini"
            icon="el-icon-s-custom"
            circle
            @click="$emit('black', scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import phone from '@/components/phoneSecret'
export default {
  components:{phone},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.query-table {
}
</style>
